// src/main.js

import './style.css';

const app = document.getElementById('app');

const contaniner = document.createElement('div');
contaniner.className = 'container';
app.appendChild(contaniner)

// 创建标题元素
const header = document.createElement('header');
header.innerHTML = '<h1>科研驱动万物 智能引领未来</h1>';

// 创建一个 img 元素并设置图片源
const img1 = document.createElement('img');
img1.src = '/assets/bg.jpg'; // 确保图片路径正确
img1.alt = '';
img1.style.width = '100%'; // 或者设置一个具体的最大宽度
// img.style.maxWidth = '1100px'; // 设置图片的最大宽度
img1.style.height = 'auto'; // 保持图片的宽高比

// 创建一个 img 元素并设置图片源
const img2 = document.createElement('img');
img2.src = '/assets/work.jpg'; // 确保图片路径正确
img2.alt = '';
img2.style.width = '100%'; // 或者设置一个具体的最大宽度
// img.style.maxWidth = '1100px'; // 设置图片的最大宽度
img2.style.height = 'auto'; // 保持图片的宽高比
// // 将 img 元素添加到容器中
// header.appendChild(img);

// 创建主要内容元素
const mainContent = document.createElement('div');
mainContent.className='main';
mainContent.innerHTML = `
<p>深圳市奇旭科技有限公司，作为一家走在科技前沿的研究型企业，我们致力于将人工智能、机器学习和深度学习的尖端技术与生命科学、环境科学、海洋学、土壤学等多个领域相结合，为客户提供全方位、高度定制化的人工智能和物联网解决方案。我们的专业团队由跨学科的专家组成，他们拥有深厚的专业知识和丰富的实践经验，能够理解并解决客户在复杂多变的实际应用场景中遇到的挑战。</p>
  <p>我们的专业领域包括海洋生态检测算法、土壤生态检测算法、微生物-弧菌科研数据服务等，旨在为客户提供从研发、设计到技术支持的一站式定制服务。我们服务过包括深圳大学、中科院深圳先进院、南京农业大学、香港科技大学等众多高校和研究机构。</p>
  <p>奇旭科技坚持以客户为中心，通过深入理解客户需求，提供更加先进、更加完善的产品和服务。我们相信，通过我们的努力，人工智能和物联网技术将在更多领域发挥重要作用，为社会的发展和进步做出贡献。</p>
  <p>若您对我们的服务感兴趣，或有任何疑问，请随时通过以下方式联系我们：</p>
  <p><strong>联系方式：</strong>email: <a href="mailto:service@daphina.net">service@daphina.net</a></p>
`;


// 创建 footer 元素
const footer = document.createElement('footer');
footer.innerHTML = `<p>Power by Daphina Technology 奇旭科技 &copy; 2024 | <a href="https://beian.miit.gov.cn" target="_blank" style="color: white;">粤ICP备2022076170号</a></p>`;


// 将元素添加到容器中
contaniner.appendChild(header);
contaniner.appendChild(mainContent);
contaniner.appendChild(img2);
contaniner.appendChild(img1);
contaniner.appendChild(footer);